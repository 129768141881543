import { useEffect, useState, useRef } from "react";
import Ad from "./ad";
import { Link } from "react-router-dom";
import { get } from "../utils/services";

export default function Ads(props) {
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);
    const adsContainerRef = useRef(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);

    useEffect(() => {
        get("ad").then((res) => {
            setLoading(false);
            setAds(res);
            // Inicializar a visibilidade do botão direito
            if (adsContainerRef.current) {
                const container = adsContainerRef.current;
                setShowRightButton(container.scrollWidth > container.clientWidth);
            }
        });
    }, []);

    const updateButtonVisibility = () => {
        const container = adsContainerRef.current;
        if (!container) return;

        setShowLeftButton(container.scrollLeft > 0);
        setShowRightButton(container.scrollLeft < container.scrollWidth - container.clientWidth);
    };

    const moveToAd = (direction) => {
        const container = adsContainerRef.current;
        if (!container) return;

        // Largura de um card (incluindo margem)
        const adWidth = container.children[0].offsetWidth + parseInt(getComputedStyle(container.children[0]).marginRight, 10);

        // Cálculo do scroll baseado na direção
        const maxScroll = container.scrollWidth - container.clientWidth;
        let newScrollPosition =
            direction === "left" ? container.scrollLeft - adWidth : container.scrollLeft + adWidth;

        // Garantir que não ultrapasse os limites
        if (newScrollPosition < 0) newScrollPosition = 0;
        if (newScrollPosition > maxScroll) newScrollPosition = maxScroll;

        // Scroll suave
        container.scrollTo({ left: newScrollPosition, behavior: "smooth" });

        setTimeout(updateButtonVisibility, 300); // Atualiza a visibilidade dos botões após o scroll
    };

    useEffect(() => {
        const container = adsContainerRef.current;
        if (container) {
            updateButtonVisibility(); // Atualiza a visibilidade ao montar o componente
            container.addEventListener("scroll", updateButtonVisibility);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", updateButtonVisibility);
            }
        };
    }, []);

    return (
        <div className={`relative transition-all select-none ${props.className}`}>
            <p className="font-ibm text-2xl font-bold text-zinc-800 dark:text-zinc-200 mb-4">
                Destaques
            </p>

            <div className="relative">
                {/* Botão esquerdo */}
                {showLeftButton && (
                    <button
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gradient-to-br from-zinc-800 to-zinc-700 text-white p-4 rounded-lg shadow-lg hover:scale-110 transition-transform z-10"
                        onClick={() => moveToAd("left")}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                    </button>
                )}

                {/* Contêiner de anúncios */}
                <div
                    className="ads flex overflow-x-hidden space-x-6 rounded-xl"
                    ref={adsContainerRef}
                >
                    {ads?.map((ad, index) => (
                        <div
                            key={index}
                            className="flex-shrink-0 w-96 rounded-xl transition-transform"
                        >
                            <Ad
                                id={ad.id}
                                name={ad.title}
                                description={ad.description}
                                image={ad.image}
                                price={ad.price}
                                user={ad.user}
                                loading={loading}
                            />
                        </div>
                    ))}
                </div>

                {/* Botão direito */}
                {showRightButton && (
                    <button
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gradient-to-br from-zinc-800 to-zinc-700 text-white p-4 rounded-lg shadow-lg hover:scale-110 transition-transform z-10"
                        onClick={() => moveToAd("right")}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                )}
            </div>
            

            {props.hub !== false && (
                <div className="w-full flex flex-col md:flex-row justify-center items-center mt-12">
                    <span className="text-lg text-zinc-700 dark:text-zinc-300 font-ibm mr-1 text-center">Para ver mais anúncios, você pode entrar no nosso</span>

                    <Link to="/hub">
                        <div className="flex p-3 rounded-full   ease-in-out cursor-pointer font-ibm font-medium text-zinc-800 hover:text-black dark:text-zinc-200 dark:hover:text-zinc-50 hover:underline hover:decoration-wavy decoration-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                            </svg>
                            <span className="ml-2">Hub de Anúncios</span>

                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
}
