import { useState, useEffect, useRef } from "react";
import Header from "../components/header";

export default function Chat() {
    const chat = useRef(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [completedTyping, setCompletedTyping] = useState(false);
    const [username, setUsername] = useState("");
    const [socket, setSocket] = useState(null); // Novo estado para armazenar a conexão WebSocket

    useEffect(() => {
        // Inicializar o WebSocket
        const ws = new WebSocket('wss://www.tourotank.com.br');

        // Quando a conexão é aberta
        ws.onopen = () => {
            console.log('Conexão WebSocket aberta');
        };

        // Quando uma mensagem é recebida
        ws.onmessage = (event) => {
            const receivedMessage = JSON.parse(event.data);
            setMessages(prevMessages => [...prevMessages, receivedMessage]);
        };

        // Quando a conexão é fechada
        ws.onclose = () => {
            console.log('Conexão WebSocket fechada');
        };

        // Salvar o WebSocket no estado
        setSocket(ws);

        // Limpar a conexão quando o componente for desmontado
        return () => {
            ws.close();
        };
    }, []);

    function sendMessage() {
        if (socket && socket.readyState === WebSocket.OPEN) {
            // Enviar a mensagem se o WebSocket estiver aberto
            const messageObject = {
                user: { name: username ? username : "null", avatar: '' },
                content: message,
            };
            socket.send(JSON.stringify(messageObject));
            setMessages([...messages, messageObject]);
            setMessage("");
        } else {
            console.log('Conexão WebSocket não está aberta');
        }
    }

    return (
        <div className="bg-white dark:bg-zinc-850">
            <Header className="!absolute" buttons={false} />
            <div className="w-full h-[calc(100vh-45px)] flex justify-center items-center pb-10">
                <div className="w-full h-full flex flex-col justify-between">
                    <div ref={chat} className="chat items-center flex flex-col w-full relative bg-white overflow-auto">
                        <div className="w-11/12 md:w-1/2 h-full z-10 mt-20">
                            {messages.map((message, index) => (
                                <div className="flex-col w-full" key={index}>
                                    {message.user &&
                                        <div className="flex items-start justify-start w-full">
                                            <div className="w-8 h-8 min-w-8 rounded-full bg-zinc-500 mr-2 mt-4 flex items-center justify-center font-montserrat relative font-bold text-white text-2xl">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <div className="flex flex-col mr-5 bg-white p-3 rounded-xl relative w-full">
                                                <span className="font-bold text-primary text-sm w-full truncate">{message.user.name}</span>
                                                <span className="font-normal text-black text-xs break-all min-h-4">{message.content}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="bottom-6 fixed flex w-11/12 md:w-1/2 h-20 items-center justify-between p-5 z-10 px-0">
                            <div className="bg-white border rounded-full z-10 w-full flex items-center px-5 pr-1.5">
                                <input onChange={(e) => setMessage(e.target.value)} value={message} type="text" className="w-full h-12 text-sm outline-none" placeholder="Digite sua mensagem" />
                                <button className="ml-5 flex items-center bg-primary text-white hover:bg-white hover:text-primary border border-primary rounded-full w-10 h-10 min-h-10 min-w-10 justify-center cursor-pointer"
                                    onClick={sendMessage}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
