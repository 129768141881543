

export default function Skeleton(props) {

    return (
        <div className={"h-full " + props.overflowAuto ? "overflow-auto" : ""}>
            {props.loading ?
                <div className={"w-full h-full overflow-hidden relative space-y-5 rounded-2xl bg-zinc-50 dark:bg-zinc-900 bg-gradient-to-r from-transparent via-zinc-100 dark:via-zinc-800 to-transparent  before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_1s_infinite] before:border-zinc-50 before:bg-gradient-to-r before:from-transparent before:via-black/10 before:to-transparent " + (props.rounded ? props.rounded : "")}>

                    <div className={props.className}></div>

                </div>
                :
                props.children
            }
        </div>
    )
}