import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import Breadcrumbs from "../templates/breadcrumbs";
import Container from "../templates/container";
import Select from "../templates/select";
import Image from "../templates/image";

import { coins, payments } from "../utils/jsons";
import { useParams } from "react-router-dom";
import { getByID, savePayment } from "../utils/services";
import Skeleton from "../templates/skeleton";
import Button from "../templates/button";


import { useContext } from "react";
import UserContext from "../utils/context";

import QRCode from 'qrcode.react';
import Modal from "../templates/modal";


export default function Buy(props) {

    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [value, setValue] = useState({ target: { value: "0" } });
    const [coin, setCoin] = useState({
        "label": "Real",
        "value": "BRL",
        "image": "https://img.icons8.com/color/48/brazil-circular.png"
    });
    const [payment, setPayment] = useState({
        "label": "Pix",
        "value": "PIX",
        "image": "https://img.icons8.com/color/48/brazil-circular.png"
    });
    const [isOpenCoin, setIsOpenCoin] = useState(false);
    const [isOpenPayment, setIsOpenPayment] = useState(false);
    const [optionsCoin, setOptionsCoin] = useState();

    const [optionsPayment, setOptionsPayment] = useState();

    const [ad, setAd] = useState({});
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const { user } = useContext(UserContext);

    const [modal, setModal] = useState(false);
    const [pix, setPix] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

        getByID("ad", id).then((response) => {
            setAd(response);
            setLoading(false);
        })


    }, [id])

    useEffect(() => {
        setCoin("BRL");
    }, []);

    useEffect(() => {

        if (ad?.price) {
            let unmask = unmaskCurrency(ad?.price);
            let val = parseFloat(unmask);
            val = val * quantity;
            val = val.toFixed(2);
            val = val.toString();

            if (val.includes(".")) {
                val = "R$" + val
            } else {
                val = "R$ " + val + ",00"
            }

            if (quantity > 1) {
                setPrice(maskCoin({ target: { value: val } }));
            } else {
                setPrice(maskCoin({ target: { value: ad?.price } }));
            }
        }
    }, [quantity]);


    useEffect(() => {
        setOptionsCoin(coins);
    }, [coins]);

    useEffect(() => {
        setOptionsPayment(payments);
    }, [payments]);

    useEffect(() => {
        maskCoin(value);
    }, [coin]);

    useEffect(() => {
        if (ad?.price) {
            setPrice(maskCoin({ target: { value: ad?.price } }));
        }
    }, [ad]);

    const unmaskCurrency = (valor) => {
        return parseFloat(valor.replace("R$", "").replace(".", "").replace(",", ".")).toFixed(2);
    }


    const maskCoin = (event) => {
        const onlyDigits = event.target.value
            .split("")
            .filter(s => /\d/.test(s))
            .join("")
            .padStart(3, "0")
        const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
        return maskCurrency(digitsFloat, 'pt-BR', "BRL");
    }

    const maskCurrency = (valor, locale, currency) => {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency
        }).format(valor)
    }

    const finishBuy = () => {
        setModal(true);

        console.log("user --> " + JSON.stringify(user));

        if (user?.displayName) {
            savePayment({ cpf: "94271564656", buyerName: user.displayName, value: unmaskCurrency(price), title: ad?.title }).then((response) => {
                console.log(response);
                setPix(response.pixCopiaECola);
            })
        } else {
            navigate("/login");
        }
    }


    return (
        <div className="bg-white dark:bg-zinc-850 overflow-x-hidden min-h-screen select-none">
            <Header />

            <Container>

                <Breadcrumbs history={[{ title: 'Início', path: '/' }, { title: 'Anuncio', path: '/ad' }, { title: 'Comprar', path: '/buy' }]} />


                <div className="flex flex-col justify-between mt-4">


                    <div className="w-full p-8 border dark:border-zinc-700 bg-white dark:bg-zinc-850 rounded-lg flex flex-col mb-5">


                        <div className="flex flex-col h-full">

                            <div className="flex flex-col md:flex-row h-full">

                                <Image loading={loading} src={ad?.image} className="object-cover md:min-w-64 min-w-full h-40" />



                                <div className="w-full mt-4 md:ml-4 md:mt-0 overflow-hidden">

                                    <div className="flex flex-col">
                                        <span className="font-ibm text-xs text-zinc-600 dark:text-zinc-400">Titulo</span>
                                        <Skeleton className="w-1/2 h-8" loading={loading}>
                                            <span className="font-ibm text-2xl font-medium text-zinc-800 dark:text-zinc-200">{ad?.title}</span>
                                        </Skeleton>
                                    </div>


                                    <div className="flex flex-col sm:flex-row">
                                        <div className="flex mt-4 item">
                                            <div className="flex flex-col mr-8 min-w-max">
                                                <span className="font-ibm mb-1s text-xs text-zinc-600 dark:text-zinc-400">Preço unidade</span>
                                                <Skeleton className="!min-w-28 h-8" loading={loading}>
                                                    <p className="font-ibm !min-w-28 h-8 text-xl font-medium text-zinc-800 dark:text-zinc-200">{ad?.price}</p>
                                                </Skeleton>
                                            </div>

                                            <div className="flex flex-col mr-8 min-w-max">
                                                <span className="font-ibm mb-1 text-xs text-zinc-600 dark:text-zinc-400">Tanks</span>
                                                <Skeleton className="!min-w-28 h-8" loading={loading}>
                                                    <p className="font-ibm !min-w-28 h-8 text-xl font-medium text-zinc-800 dark:text-zinc-200">{ad?.tanks}</p>
                                                </Skeleton>
                                            </div>


                                        </div>

                                        <div className="flex flex-col mt-4 min-w-max">
                                            <span className="font-ibm mb-1 text-xs text-zinc-600 dark:text-zinc-400">Quantidade</span>

                                            <div className="flex">
                                                <div onClick={() => setQuantity(quantity != 1 ? quantity - 1 : 1)} className="w-10 min-w-10 h-10 border dark:border-zinc-700 rounded-lg text-zinc-400 text-xl flex items-center justify-center font-ibm font-bold">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                                                    </svg>
                                                </div>

                                                <div className="flex border dark:border-zinc-700 p-2 px-4 rounded-lg dark:bg-zinc-900 outline-none font-ibm text-lg text-zinc-800 dark:text-zinc-300 dark:placeholder:text-zinc-600 ml-2 mr-2">
                                                    <input type="text"
                                                        value={quantity}
                                                        className="w-14 bg-transparent text-sm outline-none dark:placeholder:text-zinc-400 dark:text-zinc-200" />
                                                </div>


                                                <div onClick={() => setQuantity(quantity + 1)} className="w-10 min-w-10 h-10 border dark:border-zinc-700 rounded-lg text-zinc-400 text-xl flex items-center justify-center font-ibm font-bold">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="w-full p-8 border dark:border-zinc-700 bg-white dark:bg-zinc-850 rounded-lg flex flex-col">
                        <div className="flex flex-col">
                            <div className="flex w-full mb-2 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 text-secondary dark:text-zinc-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                </svg>

                                <span className="font-ibm text-xs text-zinc-600 dark:text-zinc-400">Valor do Pedido</span>
                            </div>

                            <div className="flex">
                                <div className="flex w-full border dark:border-zinc-700 p-2 px-4 rounded-lg dark:bg-zinc-900 outline-none font-ibm text-lg text-zinc-800 dark:text-zinc-300 dark:placeholder:text-zinc-600 mr-4">
                                    <input type="text" value={price} disabled
                                        onChange={(e) => {
                                            let value = maskCoin(e);
                                            setPrice(value);
                                            setValue(e);
                                        }}
                                        className="w-full bg-transparent text-sm outline-none dark:placeholder:text-zinc-400 dark:text-zinc-200" />
                                </div>

                                <Select className="mt-0" isOpen={isOpenCoin} setIsOpen={setIsOpenCoin} onChange={(option) => { setCoin(option) }} options={optionsCoin ? optionsCoin : []} disabled={true} option={coin} selected={true} />

                            </div>


                            <div className="flex w-full mb-2 mt-5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 text-secondary dark:text-zinc-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                                </svg>
                                <span className="font-ibm text-xs text-zinc-600 dark:text-zinc-400">Metodo de pagamento</span>
                            </div>
                            <Select className="mt-0" isOpen={isOpenPayment} setIsOpen={setIsOpenPayment} onChange={(option) => { setPayment(option) }} options={optionsPayment ? optionsPayment : []} disabled={true} option={payment} selected={true} />

                            <div className="mt-10">
                                <Button type="primary" className="w-full md:w-full !ml-0" onClick={finishBuy}>
                                    Finalizar
                                </Button>
                            </div>
                        </div>

                    </div>


                </div>

                {modal &&
                    <Modal title="Pagamento" setModal={setModal}>

                        {
                            (payment === "PIX" && pix) ?
                                <div className="flex flex-col items-center">
                                    <div className="p-4 bg-white rounded-lg">
                                        <QRCode value={pix} size={144} />
                                    </div>


                                    <div className="bg-white flex w-full md:w-56 p-2 mt-4 rounded">
                                        <p className="flex mr-2 items-center w-full max-w-full text-zinc-900 truncate">
                                            {pix}
                                        </p>

                                        <div className="bg-zinc-100 border border-zinc-200 rounded-md p-2 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-zinc-900 size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                            </svg>

                                        </div>
                                    </div>

                                    <span className="font-ibm text-zinc-800 dark:text-zinc-200 text-base text-center mt-4">Escaneie o QR Code ou copie o código para pagar</span>
                                </div>
                                :
                                <div className="flex flex-col items-center">
                                    <div className="p-4 bg-white rounded-lg">
                                        <div className="w-36 h-36 flex items-center justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-black w-16 h-16 animate-spin">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                                            </svg>
                                        </div>
                                    </div>


                                    <div className="bg-white flex w-full md:w-56 p-2 mt-4 rounded">
                                        <p className="flex mr-2 items-center w-full max-w-full text-zinc-900 truncate">
                                            {pix}
                                        </p>

                                        <div className="bg-zinc-100 border border-zinc-200 rounded-md p-2 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-zinc-900 size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                            </svg>

                                        </div>
                                    </div>

                                    <span className="font-ibm text-zinc-800 dark:text-zinc-200 text-base text-center mt-4">Escaneie o QR Code ou copie o código para pagar</span>
                                </div>
                        }


                    </Modal>
                }

            </Container >
        </div >
    )
}
