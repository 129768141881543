import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../templates/button";

export default function Banner() {
    const [select, setSelect] = useState("anunciar");



    return (
        <div className="w-full px-5 md:px-10 overflow-hidden h-[50vh] pr-20 md:pr-10 pt-20 font-rajdhani relative select-none">

            <div className="md:w-3/4 h-full flex flex-col justify-center">

                <h2 className="flex text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-zinc-700 dark:text-zinc-300 z-10 relative items-center flex-col md:flex-row"><span className="flex items-center">A <span className="bg-primary text-white p-2 m-2">NEGOCIAÇÃO</span></span><span>SE RESUME A DOIS</span></h2>

                <div className="flex items-center flex-col md:flex-row">
                    <h1 className="flex text-5xl md:text-8xl xl:text-9xl   font-bold text-zinc-700 dark:text-zinc-300 z-10 relative">CLIQUES</h1>

                    <div className="flex items-center">
                        <h1 className={"flex ml-4 text-5xl md:text-8xl font-bold text-primary z-10 relative transition-all " + (select == "comprar" ? "rotate-30" : "")}><img className="min-w-24 w-24 h-16 mr-5" src="/imgs/cursor/point.png" alt="Mao"></img></h1>

                        <div className="flex flex-col mt-4 -ml-4 items-center justify-center">
                            <Link to="/ad/new" onMouseEnter={() => { setSelect("anunciar") }}>
                                <p className={"transition-all ml-4 font-montserkrat text-4xl text-zinc-700 dark:text-zinc-200 hover:text-primary font-bold " + (select == "anunciar" ? "!text-primary" : "")}>ANUNCIAR</p>
                            </Link>
                            <Link to="/hub" onMouseEnter={() => { setSelect("comprar") }}>
                                <p className={"transition-all ml-4 font-montserkrat text-4xl text-zinc-700 dark:text-zinc-200 hover:text-primary font-bold " + (select == "comprar" ? "!text-primary" : "")}>COMPRAR</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}