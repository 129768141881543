import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { games } from "../utils/jsons";

export default function Games() {
    const gamesContainerRef = useRef(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);

    const updateButtonVisibility = () => {
        const container = gamesContainerRef.current;
        if (!container) return;

        setShowLeftButton(container.scrollLeft > 0);
        setShowRightButton(container.scrollLeft < container.scrollWidth - container.clientWidth);
    };

    const moveScroll = (direction) => {
        const container = gamesContainerRef.current;
        if (!container) return;

        // Largura de um card (incluindo margem)
        const gameWidth = container.children[0].offsetWidth + parseInt(getComputedStyle(container.children[0]).marginRight, 10);

        // Cálculo do scroll baseado na direção
        const maxScroll = container.scrollWidth - container.clientWidth;
        let newScrollPosition =
            direction === "left" ? container.scrollLeft - gameWidth : container.scrollLeft + gameWidth;

        // Garantir que não ultrapasse os limites
        if (newScrollPosition < 0) newScrollPosition = 0;
        if (newScrollPosition > maxScroll) newScrollPosition = maxScroll;

        // Scroll suave
        container.scrollTo({ left: newScrollPosition, behavior: "smooth" });

        setTimeout(updateButtonVisibility, 300); // Atualiza a visibilidade dos botões após o scroll
    };

    useEffect(() => {
        updateButtonVisibility(); // Atualiza a visibilidade ao montar o componente
        const container = gamesContainerRef.current;
        if (container) {
            container.addEventListener("scroll", updateButtonVisibility);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", updateButtonVisibility);
            }
        };
    }, []);

    return (
        <div className="h-96 transition flex flex-col mb-10 relative">
            <p className="font-ibm text-2xl font-bold text-black dark:text-zinc-300 mb-4">Todos os Jogos</p>

            {/* Botão esquerdo */}
            {showLeftButton && (
                <button
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gradient-to-br from-zinc-800 to-zinc-700 text-white p-4 rounded-lg shadow-lg hover:scale-110 transition-transform z-10"
                    onClick={() => moveScroll("left")}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                </button>
            )}

            {/* Contêiner de jogos */}
            <div className="games flex overflow-hidden space-x-6 rounded-xl" ref={gamesContainerRef}>
                {games.sort((a, b) => a.rank - b.rank).map((game, index) => {
                    if (game.banner) {
                        return (
                            <Link
                                key={index}
                                to={`/category/${game.value}`}
                                className="min-w-44 mr-4 relative border border-transparent"
                            >
                                <div className="rounded-lg">
                                    {game.image ? (
                                        <img
                                            src={game.banner}
                                            alt={game.label}
                                            className="h-64 mb-2 text-zinc-600 dark:text-zinc-400 rounded-md object-cover"
                                        />
                                    ) : null}
                                </div>
                                <span className="font-ibm text-base font-bold text-black dark:text-zinc-300 !m-0 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    {game.label}
                                </span>
                            </Link>
                        );
                    }
                    return null;
                })}
            </div>

            {/* Botão direito */}
            {showRightButton && (
                <button
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gradient-to-br from-zinc-800 to-zinc-700 text-white p-4 rounded-lg shadow-lg hover:scale-110 transition-transform z-10"
                    onClick={() => moveScroll("right")}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            )}
        </div>
    );
}
