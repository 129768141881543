import { Link } from "react-router-dom";
import Button from "../templates/button";
import Logo from "../templates/logo";
import Menu from "../templates/menu";

import { useContext, useEffect, useState } from "react";
import UserContext from "../utils/context";
import Categories from "../templates/categories";

export default function Header(props) {
  const { user } = useContext(UserContext);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full select-none px-5 md:px-10 h-20 flex items-center justify-between z-20 fixed transition-all duration-300 ${
        isScrolled ? "bg-white/90 dark:bg-zinc-850 backdrop-blur-lg shadow-md" : "bg-transparent"
      } ${props.className}`}
    >
      <div className="flex items-center">
        <Logo />
        <div className="cursor-pointer ml-4 px-2 bg-primary text-white rounded-[4px] hidden lg:flex items-center justify-center">
          <span className="text-xs font-ibm">alpha 0.3</span>
        </div>
      </div>

      <div className="flex items-center justify-between">
        {props.buttons != false && (
          <>
            <Categories />

            {props.search && (
              <div className="hidden md:flex items-center justify-between mr-4 rounded-lg bg-zinc-100 dark:bg-zinc-900 h-11 px-4 pl-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-zinc-400 dark:text-zinc-500 mr-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>

                <input
                  type="text"
                  className="w-full outline-none text-ibm text-zinc-700 bg-transparent dark:text-zinc-400 dark:placeholder:text-zinc-500"
                  placeholder="Pesquisar"
                />
              </div>
            )}

            {user?.email && (
              <Link to="/ad/new" className="mr-4">
                <Button type="primary" className="w-36 h-full">
                  Anunciar
                </Button>
              </Link>
            )}

            {!user.email ? (
              <Link to="/login">
                <Button type="primary" className="w-36 h-full">
                  Entrar
                </Button>
              </Link>
            ) : (
              <Menu />
            )}
          </>
        )}
      </div>
    </div>
  );
}
